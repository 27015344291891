<template>
  <div class="main-content">
    <div class="main-title">作品详情</div>
    <div class="from">
      <div class="row line-border">
        <div class="row-box">
          <div class="image-box mb35">
            <div class="title">作品封面</div>
            <div class="description">点击下方下载文件</div>
            <a :href="worksObj.cover_img+'?response-content-disposition=attachment'">
              <img :src="worksObj.cover_img" alt=" ">
            </a>
            <div class="title">作品说明</div>
            <div class="description" v-if="worksObj.document_path != ''">点击下方下载文件</div>
            <a v-if="worksObj.document_path != ''" :href="worksObj.document_path+'?response-content-disposition=attachment'">
              <img :src="worksObj.document_preview" alt=" ">
            </a>
            <div class="document" v-if="worksObj.document != ''">{{worksObj.document}}</div>
          </div>
          <div class="info-box">
            <div class="block-box w70">
              <div class="title">参赛作品名称</div>
              <div class="value">{{worksObj.title}}</div>
            </div>
            <div class="block-box w30 pl15">
              <div class="title">参赛作品是否为实物</div>
              <div class="value">{{worksObj.is_real}}</div>
            </div>
            <div class="block-box w70">
              <div class="title">{{this.label_company_name}}</div>
              <div class="value">{{worksObj.company}}</div>
            </div>
            <div class="block-box w30 pl15">
              <div class="title">作品类型</div>
              <div class="value">{{worksObj.cate}}</div>
            </div>
            <div class="block-box w33">
              <div class="title">参赛作者姓名1</div>
              <div class="value">{{worksObj.author1}}</div>
            </div>
            <div class="block-box w33 pl15">
              <div class="title">参赛作者姓名2</div>
              <div class="value">{{worksObj.author2}}</div>
            </div>
            <div class="block-box w33 pl15">
              <div class="title">参赛作者姓名3</div>
              <div class="value">{{worksObj.author3}}</div>
            </div>
            <div class="block-box w33">
              <div class="title">参赛作者姓名4</div>
              <div class="value">{{worksObj.author4}}</div>
            </div>
            <div class="block-box w33 pl15">
              <div class="title">参赛作者姓名5</div>
              <div class="value">{{worksObj.author5}}</div>
            </div>
            <div class="block-box w33 pl15">
              <div class="title">参赛作者姓名6</div>
              <div class="value">{{worksObj.author6}}</div>
            </div>
            <div class="block-box w33">
              <div class="title">团队联系人电话</div>
              <div class="value">{{worksObj.team_phone}}</div>
            </div>
            <div class="block-box w30" v-if="worksObj.race === '1'">
              <div class="title">指导老师姓名1</div>
              <div class="value">{{worksObj.teacher_realname1}}</div>
            </div>
            <div class="block-box w70 pl15" v-if="worksObj.race === '1'">
              <div class="title">电话</div>
              <div class="value">{{worksObj.teacher_phone1}}</div>
            </div>
            <div class="block-box w30" v-if="worksObj.race === '1'">
              <div class="title">指导老师姓名2</div>
              <div class="value">{{worksObj.teacher_realname2}}</div>
            </div>
            <div class="block-box w70 pl15" v-if="worksObj.race === '1'">
              <div class="title">电话</div>
              <div class="value">{{worksObj.teacher_phone2}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row line-border">
        <div class="row-box mb35">
          <div class="image-box">
            <div class="title">展板文件</div>
            <div class="description">点击下方下载文件</div>
            <a :href="worksObj.board_path+'?response-content-disposition=attachment'">
              <img :src="worksObj.board_preview" alt=" ">
            </a>
          </div>
        </div>
      </div>
      <div class="row line-border">
        <div class="row-box mb35">
          <div class="file-box">
            <div class="title">作品文件</div>
            <div class="description">点击下方下载文件</div>
            <template v-for="(item) in worksObj.worksFiles">
              <a :href="item.works_path+'?response-content-disposition=attachment'" :key="item.id">
                <img :src="item.preview_url" alt=" ">
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row-box mb35">
          <div class="image-box">
            <div class="title">承诺书文件</div>
            <div class="description">点击下方下载文件</div>
            <a :href="worksObj.promise_path+'?response-content-disposition=attachment'">
              <img :src="worksObj.promise_preview" alt=" ">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "works_info_base",
  props:{
    worksObj:{
      type:Object,
      default:()=>{}
    }
  },
  components:{

  },
  data() {
    return {
      label_company_name:''
    }
  },
  computed: {

  },
  created() {
    console.log(this.worksObj)
    if(this.worksObj.race == '1'){
      this.label_company_name = '院校名称'
    }else if(this.worksObj.race == '2'){
      this.label_company_name = '公司名称'
    }else {
      this.label_company_name = '院校/公司名称'
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .row-box{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        .image-box{
          width: 40%;
          padding-right: 60px;
          .title{
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: bold;
            color: #000000;
            margin-top: 15px;
          }
          .description{
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #5E5D5D;
            margin-bottom: 25px;
          }
          .document{
            height: 270px;
            padding: 10px;
            border: 0.5px solid $blockColor--1;
          }
          img {
            display: block;
            width: 100%;
            height: 270px;
            object-fit: contain;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
          }
        }
        .info-box{
          width: 60%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          float: left;
          position: relative;
          .block-box{
            width: 100%;
            margin-top: 15px;
            margin-bottom: 35px;
          }
          .w30{
            width: 30%;
          }
          .w33{
            width: 33%;
          }
          .w70{
            width: 70%;
          }
          .pl15{
            padding-left: 15px;
          }
          .pr15{
            padding-right: 15px;
          }
          .title{
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #000000;
            margin-bottom: 15px;
          }
          .value{
            height: 50px;
            width: 100%;
            display:flex;/*实现垂直居中*/
            align-items:center;/*实现水平居中*/
            border-radius: 25px 25px 25px 25px;
            opacity: 1;
            border: 2px solid #DDD1B4;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 30px;
          }
        }
        .file-box{
          width: 100%;
          padding-right: 60px;
          .title{
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: bold;
            color: #000000;
            margin-top: 15px;
          }
          .description{
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #5E5D5D;
            margin-bottom: 25px;
          }
          img {
            display: block;
            width: 220px;
            height: 220px;
            object-fit: contain;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
            float: left;
            margin-right: 15px;
          }
        }
        .mb35{
          margin-bottom: 35px;
        }
      }
      .mb35{
        margin-bottom: 35px;
      }
    }
    .line-border{
      border-bottom: 0.5px solid $blockColor--1;

    }
  }
}
</style>
