<template>
  <div class="main-content">
    <div class="main-title">作品信息</div>
    <div class="from">
      <div class="row">
        <div class="row-box">
          <div class="block-box w40">
            <div class="title">赛道</div>
            <div class="value">{{worksObj.raceObj.name}}</div>
          </div>
          <div class="block-box w60 pl15">
            <div class="title">作品编号</div>
            <div class="value">{{worksObj.work_no}}</div>
          </div>
          <div class="block-box w40">
            <div class="title">主题</div>
            <div class="value">{{worksObj.raceTopicObj.name}}</div>
          </div>
          <div class="block-box w60 pl15">
            <div class="title">手机号</div>
            <div class="value">{{worksObj.userObj.phone}}</div>
          </div>
        </div>
        <div class="row-box">
          <div class="block-box pl15">
            <div class="title">真实姓名</div>
            <div class="value">{{worksObj.userObj.realname}}</div>
          </div>
          <!--
          <div class="block-box w50 pl15">
            <div class="title">获奖露出名称</div>
            <div class="value">{{worksObj.school}}</div>
          </div>-->
          <div class="block-box pl15">
            <div class="title">邮箱</div>
            <div class="value">{{worksObj.userObj.email}}</div>
          </div>
        </div>
        <div class="row-box">
          <div class="block-box pl15">
            <div class="title">性别</div>
            <div class="value">男</div>
          </div>
          <div class="block-box pl15">
            <div class="title">联系地址</div>
            <div class="value">{{worksObj.userObj.address}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "works_info_base",
  props:{
    worksObj:{
      type:Object,
      default:()=>{}
    }
  },
  components:{

  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {
    console.log(this.worksObj)
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      .row-box{
        width: 33%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        float: left;
        position: relative;
        .block-box{
          width: 100%;
          margin-top: 15px;
        }
        .w30{
          width: 30%;
        }
        .w70{
          width: 70%;
        }
        .w40{
          width: 40%;
        }
        .w60{
          width: 60%;
        }
        .w50{
          width: 60%;
        }
        .pl15{
          padding-left: 15px;
        }
        .pr15{
          padding-right: 15px;
        }
        .title{
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .value{
          height: 50px;
          width: 100%;
          display:flex;/*实现垂直居中*/
          align-items:center;/*实现水平居中*/
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 30px;
        }
      }
    }
  }
}
</style>
