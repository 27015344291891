<template>
  <div class="main-content-box">
    <infoBaseEl :worksObj="worksObj"/>
    <infoWorksEl :worksObj="worksObj"/>
  </div>
</template>

<script>
import infoBaseEl from "@/views/work/info_base";
import infoWorksEl from "@/views/work/info_works";
import {getWorksInfo} from "@/request/api";

export default {
  name: "works_info",
  props:{

  },
  components:{
    infoBaseEl,
    infoWorksEl
  },
  data() {
    return {
      id:0,
      worksObj:{},
    }
  },
  computed: {

  },
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  mounted() {

  },
  methods: {
    getInfo(){
      const requestData = {"id":this.id};
      getWorksInfo(requestData).then((res)=>{
        this.worksObj = res.data;
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
